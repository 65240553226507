import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Hero = () => {
  return (
    <section className="hero container">
      <div className="hero-body">
        <div className="columns hero-hb">
            <div className="column hero-hb__sub">
              <h1 className="title didact">
                human music for a digital world...
              </h1>
              <iframe title ="substack" className="substack-iframe" src="https://hotbotprod.substack.com/embed"  frameborder="0" scrolling="no"></iframe>
            </div>
            <div className="column hero-hb__img">
              <StaticImage
                src="../assets/hotbot.png"
                width={256}
                height={256}
                alt="hot bot"
                className="footer__img"
              />
            </div>
        </div>
      </div>
  </section>
  )
}

export default Hero
