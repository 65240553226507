import React from 'react'

const TwitchStream = () => {
    return (
        <section className="twitch bg-light-blue">
            <div className="container">
                <h2 className="title didact">Follow on Twitch</h2>
                <iframe title="twitch-iframe" className="twitch-iframe" src="https://player.twitch.tv/?channel=hotbotproductions&parent=www.hotbotproductions.com" frameborder="0" allowfullscreen="true" scrolling="no" height="500" width="620"></iframe>
            </div>
        </section>
    )
}

export default TwitchStream
