import React from 'react'
import Layout from '../components/Layout'
// import Posts from '../components/Posts'
import Hero from '../components/Hero'
import TwitchStream from '../components/TwitchStream'
import { graphql } from 'gatsby'
import SoundCloud from '../components/SoundCloud'

const IndexPage = ({ data }) => {
  const {
    allMdx: { nodes: posts },
  } = data
  return (
    <Layout>
      <Hero />
      <TwitchStream />
      <SoundCloud />
      {/* <Posts posts={posts} title="Latest Articles" /> */}
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(limit: 8, sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          title
          author
          category
          listening
          musicLink
          date(formatString: "MMMM Do, YYYY")
          slug
          readTime
        }
        id
      }
    }
  }
`

export default IndexPage
