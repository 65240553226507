import React from 'react'

const SoundCloud = () => {
    return (
        <section className="soundcloud">
            <div className="container">
                <h2 className="title didact">Latest Releases</h2>

                <iframe title="drift" width="100%" height="166" className="soundcloud-iframe" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1152688066&color=%23dcb4b4&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>

                <iframe title="playground" width="100%" height="166" className="soundcloud-iframe" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1146354325&color=%23dcb4b4&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>

                <iframe title="bleaking" width="100%" height="166" className="soundcloud-iframe" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1141315006&color=%23dcb4b4&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>

                <iframe title="quarks" width="100%" height="166" className="soundcloud-iframe" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1137388279&color=%23dcb4b4&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            </div>
        </section>
    )
}

export default SoundCloud
